import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Faq.css";
import data from "./data.json";

import img1 from "../../images/park/img1.png";
import img2 from "../../images/park/img2.png";
import map from "../../images/park/map.png";

import { Button } from "../../globalStyles";
import { IconContext } from "react-icons/lib";
import "../InfoSection/InfoSectionHome.css";
import {
    FaqSection,
    FaqWrapper,
    FaqHeading,
    FaqContainer,
    FaqCard,
    FaqCardInfo,
    FaqCard1,
    FaqCardInfo1,
    FaqCardIcon,
    FaqCardPlan1,
    FaqCardPlan2,
    FaqCardCost,
    FaqCardLength,
    FaqCardFeatures,
    FaqCardFeatures1,
    FaqCardFeature,
    FaqCardFeature1,
    FaqInput,
} from "./Faq.elements";

import { Collect } from "js-array-collections";

import { FaFacebook, FaInstagram, FaWhatsapp, FaTelegram, FaPhone, FaMapMarker } from "react-icons/fa";

function Faq() {
    const [words, setWords] = useState([]);
    const [groups, setGroups] = useState([]);
    const [searchGl, setSearchGl] = useState("");

    useEffect(() => {
        const dataCollect = new Collect(data);
        const gl = dataCollect.groupBy("group");
        const gl_k = Object.keys(dataCollect.groupBy("group"));
        setGroups(gl_k);
        setWords(gl);
    }, []);

    useEffect(() => {
        const dataCollect =
            searchGl.length < 1
                ? new Collect(data)
                : new Collect(data.filter((e) => e.description.toLowerCase().includes(searchGl.toLowerCase()) || e.group.toLowerCase().includes(searchGl.toLowerCase()) || e.title.toLowerCase().includes(searchGl.toLowerCase())));
        const gl = dataCollect.groupBy("group");
        const gl_k = Object.keys(dataCollect.groupBy("group"));
        setGroups(gl_k);
        setWords(gl);
    }, [searchGl]);

    return (
        <IconContext.Provider
            value={{
                color: "#a9b3c1",
                size: 64,
            }}
        >
            <FaqSection>
                <FaqWrapper>
                    <FaqHeading>Le FAQ</FaqHeading>
                    <FaqContainer>
                        <FaqCard1>
                            <FaqCardInfo1>
                                <FaqCardPlan1>Ricerca</FaqCardPlan1>
                                <FaqCardFeatures style={{ width: "90%" }}>
                                    <FaqCardFeature style={{ width: "100%" }}>
                                        <FaqInput
                                            value={searchGl}
                                            onChange={(e) => {
                                                setSearchGl(e.target.value);
                                            }}
                                        />
                                    </FaqCardFeature>
                                </FaqCardFeatures>
                            </FaqCardInfo1>
                        </FaqCard1>
                        {groups.map((i, idx1) => {
                            return (
                                <FaqCard key={"GC1-" + idx1}>
                                    <FaqCardInfo key={"GC2-" + idx1}>
                                        <FaqCardPlan1 key={"GC3-" + idx1}>{i}</FaqCardPlan1>
                                        {words[i].map((e, idx2) => {
                                            return (
                                                <FaqCardFeatures1 key={"GC5-" + idx1 + "-" + idx2}>
                                                    <FaqCardFeature1 key={"GC6-" + idx1 + "-" + idx2}>
                                                        <p key={"GC7-" + idx1 + "-" + idx2}>
                                                            <span key={"GC8-" + idx1 + "-" + idx2} style={{ color: "#5ea9c4" }}>
                                                                {e.title}
                                                            </span>{" "}
                                                            <br />
                                                            <span key={"GC9-" + idx1 + "-" + idx2} dangerouslySetInnerHTML={{ __html: e.description }} />
                                                        </p>
                                                    </FaqCardFeature1>
                                                </FaqCardFeatures1>
                                            );
                                        })}
                                    </FaqCardInfo>
                                </FaqCard>
                            );
                        })}
                    </FaqContainer>
                </FaqWrapper>
            </FaqSection>
        </IconContext.Provider>
    );
}
export default Faq;
