import React from "react";
import { InfoSection, Service, Faq } from "../../components";
import { homeObjOne, homeObjThree } from "./Data";

function Services() {
    return (
        <>
            <Faq />
        </>
    );
}

export default Services;
