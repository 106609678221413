import React from "react";
import { Link } from "react-router-dom";
import "./Contact.css";

import img1 from "../../images/park/img1.png";
import img2 from "../../images/park/img2.png";
import map from "../../images/park/map.png";

import { Button } from "../../globalStyles";
import { IconContext } from "react-icons/lib";
import "../InfoSection/InfoSectionHome.css";
import {
    ContactSection,
    ContactWrapper,
    ContactHeading,
    ContactContainer,
    ContactCard,
    ContactCardInfo,
    ContactCardIcon,
    ContactCardPlan,
    ContactCardCost,
    ContactCardLength,
    ContactCardFeatures,
    ContactCardFeature,
} from "./Contact.elements";

import {
    FaFacebook,
    FaInstagram,
    FaWhatsapp,
    FaTelegram,
    FaPhone,
    FaMapMarker,
} from "react-icons/fa";

function Contact() {
    return (
        <IconContext.Provider value={{ color: "#a9b3c1", size: 64 }}>
            <ContactSection>
                <ContactWrapper>
                    <ContactHeading>I nostri contatti</ContactHeading>
                    <ContactContainer>
                        <ContactCard>
                            <ContactCardInfo>
                                <ContactCardPlan>Orario</ContactCardPlan>
                                <ContactCardFeatures>
                                    <ContactCardFeature>
                                        <p>
                                            Si riceve su appuntamento chiamando
                                            telefonicamente.
                                        </p>
                                        <p>
                                            Saremo lieti di fissarVi un
                                            appuntamento telefonico per poter
                                            decidere l’orario ed il giorno che
                                            Vi fa più comodo compatibilmente con
                                            la nostra disponibilità.
                                        </p>
                                    </ContactCardFeature>
                                    <a href={"tel:0456103637"}>
                                        <Button
                                            big
                                            fontBig
                                            primary={"primary"}
                                            style={{
                                                margin: "10px",
                                                marginTop: "3em",
                                                // width: "300px",
                                                height: "50px",
                                            }}
                                            className="home-buttons"
                                        >
                                            <FaPhone
                                                style={{
                                                    width: "1.5em",
                                                    marginRight: "10px",
                                                    color: "fff",
                                                }}
                                            />
                                            Chiama
                                        </Button>
                                    </a>
                                </ContactCardFeatures>
                            </ContactCardInfo>
                        </ContactCard>
                        <ContactCard>
                            <ContactCardInfo>
                                <ContactCardPlan>
                                    Come raggiungerci?
                                </ContactCardPlan>
                                <ContactCardFeatures>
                                    <ContactCardFeature>
                                        <b>
                                            <u>
                                                <div
                                                    style={{ color: "#335c6b" }}
                                                >
                                                    NON SIAMO VISIBILI DALLA
                                                    STRADA
                                                </div>
                                            </u>
                                        </b>
                                        <br />
                                        <p>
                                            Google maps vi porterà in auto fino
                                            al parcheggio rialzato del complesso
                                            residenziale di condominio Europa in
                                            Viale Europa. Potrete parcheggiare
                                            lì. Una volta parcheggiato Passione
                                            Dentale è raggiungibile a piedi e si
                                            trova al piano terra del condominio
                                            in fondo al vialetto pedonale. Se i
                                            parcheggi sono esauriti nel
                                            parcheggio di viale Europa, potrete
                                            parcheggiare sull’ampio parcheggio
                                            delle scuole medie raggiungibile
                                            girando attorno al distributore. Una
                                            volta parcheggiato, si torna
                                            indietro a piedi per qualche decina
                                            di metri e si prende il vialetto
                                            pedonale sulla sinistra. Impostando
                                            raggiungi a piedi su Google Maps
                                            troverete le indicazioni per
                                            arrivare sino all’ingresso dello
                                            studio.
                                        </p>
                                        <br />
                                        <br />
                                        <iframe
                                            frameBorder="0"
                                            scrolling="no"
                                            marginHeight="0"
                                            marginWidth="0"
                                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Viale%20Europa%2020/d,%20Monteforte%20d'Alpone+(passione%20dentale)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                            style={{
                                                width: "100%",
                                                height: "50vh",
                                            }}
                                        ></iframe>
                                    </ContactCardFeature>
                                </ContactCardFeatures>
                            </ContactCardInfo>
                        </ContactCard>
                        <ContactCard>
                            <ContactCardInfo>
                                <ContactCardPlan>
                                    Mappa Parcheggi
                                </ContactCardPlan>
                                <ContactCardFeatures>
                                    <ContactCardFeature>
                                        <img className="park-img" src={map} />
                                    </ContactCardFeature>
                                </ContactCardFeatures>
                            </ContactCardInfo>
                        </ContactCard>
                        <ContactCard>
                            <ContactCardInfo>
                                <ContactCardPlan>
                                    Parcheggio Europa
                                </ContactCardPlan>
                                <ContactCardFeatures>
                                    <ContactCardFeature>
                                        <img className="park-img" src={img1} />
                                    </ContactCardFeature>
                                </ContactCardFeatures>
                            </ContactCardInfo>
                        </ContactCard>
                        <ContactCard>
                            <ContactCardInfo>
                                <ContactCardPlan>
                                    Parcheggio Scuole
                                </ContactCardPlan>
                                <ContactCardFeatures>
                                    <ContactCardFeature>
                                        <img className="park-img" src={img2} />
                                    </ContactCardFeature>
                                </ContactCardFeatures>
                            </ContactCardInfo>
                        </ContactCard>
                    </ContactContainer>
                </ContactWrapper>
            </ContactSection>
        </IconContext.Provider>
    );
}
export default Contact;
