import React from "react";
import "./InfoSectionHome.css";
import { Container, Button } from "../../globalStyles";
import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    ImgWrapper,
    Img,
} from "./InfoSection.elements";

function InfoSection({
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonInfo,
    img,
    alt,
    imgStart,
    start,
}) {
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                                <TopLine lightTopLine={lightTopLine}>
                                    {topLine}
                                </TopLine>
                                <Heading lightText={lightText}>
                                    {headline}
                                </Heading>
                                <Subtitle lightTextDesc={lightTextDesc}>
                                    {description}
                                </Subtitle>
                                {buttonInfo.map((e, i, ar) => (
                                    <a href={e.link} key={"a-" + i}>
                                        <Button
                                            key={"b-" + i}
                                            big
                                            fontBig
                                            primary={"primary"}
                                            style={{
                                                margin: "10px",
                                                width: "300px",
                                            }}
                                            className="home-buttons"
                                        >
                                            {e.icon}
                                            {e.label}
                                        </Button>
                                    </a>
                                ))}
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <ImgWrapper start={start}>
                                <Img src={img} alt={alt} />
                            </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    );
}

export default InfoSection;
