import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Glossary.css";
import data from "./data.json";

import img1 from "../../images/park/img1.png";
import img2 from "../../images/park/img2.png";
import map from "../../images/park/map.png";

import { Button } from "../../globalStyles";
import { IconContext } from "react-icons/lib";
import "../InfoSection/InfoSectionHome.css";
import {
    GlossarySection,
    GlossaryWrapper,
    GlossaryHeading,
    GlossaryContainer,
    GlossaryCard,
    GlossaryCardInfo,
    GlossaryCard1,
    GlossaryCardInfo1,
    GlossaryCardIcon,
    GlossaryCardPlan1,
    GlossaryCardPlan2,
    GlossaryCardCost,
    GlossaryCardLength,
    GlossaryCardFeatures,
    GlossaryCardFeatures1,
    GlossaryCardFeature,
    GlossaryCardFeature1,
    GlossaryInput,
} from "./Glossary.elements";

import { Collect } from "js-array-collections";

import { FaFacebook, FaInstagram, FaWhatsapp, FaTelegram, FaPhone, FaMapMarker } from "react-icons/fa";

function Glossary() {
    const [words, setWords] = useState([]);
    const [groups, setGroups] = useState([]);
    const [searchGl, setSearchGl] = useState("");

    useEffect(() => {
        const dataCollect = new Collect(data);
        const gl = dataCollect.orderBy("title", "asc").groupBy("group");
        const gl_k = Object.keys(dataCollect.orderBy("title", "asc").groupBy("group"));
        setGroups(gl_k);
        setWords(gl);
    }, []);

    useEffect(() => {
        const dataCollect = searchGl.length < 1 ? new Collect(data) : new Collect(data.filter((e) => e.description.toLowerCase().includes(searchGl.toLowerCase()) || e.title.toLowerCase().includes(searchGl.toLowerCase())));
        const gl = dataCollect.orderBy("title", "asc").groupBy("group");
        const gl_k = Object.keys(dataCollect.orderBy("title", "asc").groupBy("group"));
        setGroups(gl_k);
        setWords(gl);
    }, [searchGl]);

    return (
        <IconContext.Provider
            value={{
                color: "#a9b3c1",
                size: 64,
            }}
        >
            <GlossarySection>
                <GlossaryWrapper>
                    <GlossaryHeading>Il Glossario</GlossaryHeading>
                    <GlossaryContainer>
                        <GlossaryCard1>
                            <GlossaryCardInfo1>
                                <GlossaryCardPlan1>Ricerca</GlossaryCardPlan1>
                                <GlossaryCardFeatures style={{ width: "90%" }}>
                                    <GlossaryCardFeature style={{ width: "100%" }}>
                                        <GlossaryInput
                                            value={searchGl}
                                            onChange={(e) => {
                                                setSearchGl(e.target.value);
                                            }}
                                        />
                                    </GlossaryCardFeature>
                                </GlossaryCardFeatures>
                            </GlossaryCardInfo1>
                        </GlossaryCard1>
                        {searchGl.length < 1 && (
                            <GlossaryCard>
                                <GlossaryCardInfo>
                                    <GlossaryCardPlan1>Prefazione</GlossaryCardPlan1>
                                    <GlossaryCardFeatures>
                                        <GlossaryCardFeature>
                                            Questa parte del sito è rivolta ai pazienti per i pazienti, con l’intento di fare della vera e propria informazione sanitaria. Qui cercherò di esprimere con termini di uso comune e nel modo più semplice
                                            possibile (a volte anche con piccole imprecisioni, se lette da colleghi) i concetti medici di parole odontoiatriche. Tutto questo allo scopo di far comprendere a chi non è del “mestiere”, termini il cui
                                            significato non sempre è chiaro. Poi un pizzico di simpatia qua e là non guasta.
                                        </GlossaryCardFeature>
                                    </GlossaryCardFeatures>
                                </GlossaryCardInfo>
                            </GlossaryCard>
                        )}
                        {groups.map((i, idx1) => {
                            return (
                                <GlossaryCard key={"GC1-" + idx1}>
                                    <GlossaryCardInfo key={"GC2-" + idx1}>
                                        <GlossaryCardPlan1 key={"GC3-" + idx1}>{i}</GlossaryCardPlan1>
                                        {words[i].map((e, idx2) => {
                                            return (
                                                <GlossaryCardFeatures1 key={"GC5-" + idx1 + "-" + idx2}>
                                                    <GlossaryCardFeature1 key={"GC6-" + idx1 + "-" + idx2}>
                                                        <p key={"GC7-" + idx1 + "-" + idx2}>
                                                            <span style={{ color: "#5ea9c4" }}>{e.title}</span>: {e.description}
                                                        </p>
                                                    </GlossaryCardFeature1>
                                                </GlossaryCardFeatures1>
                                            );
                                        })}
                                    </GlossaryCardInfo>
                                </GlossaryCard>
                            );
                        })}
                    </GlossaryContainer>
                </GlossaryWrapper>
            </GlossarySection>
        </IconContext.Provider>
    );
}
export default Glossary;
