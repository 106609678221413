import { Link } from "react-router-dom";
import styled from "styled-components";

export const ContactSection = styled.div`
    padding: 100px 0 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
`;

export const ContactWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    @media screen and (max-width: 960px) {
        margin: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const ContactHeading = styled.h1`
    color: #1c2237;
    font-size: 48px;
    margin-bottom: 24px;
`;

export const ContactContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 960px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`;

export const ContactCard = styled.div`
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
    width: 70%;
    min-height: 330px;
    text-decoration: none;
    border-radius: 4px;
    margin: 2em;

    &:nth-child(2) {
        margin: 24px;
    }

    &:hover {
        // transform: scale(1.06);
        transition: all 0.3s ease-out;
        color: #1c2237;
    }

    @media screen and (max-width: 960px) {
        width: 95%;
        min-height: 0px;

        &:hover {
            transform: none;
        }
    }
`;

export const ContactCardInfo = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 330px;
    padding: 24px;
    align-items: center;
    color: #fff;
    @media screen and (max-width: 960px) {
        min-height: 0px;
    }
`;

export const ContactCardIcon = styled.div`
    margin: 24px 0;
`;

export const ContactCardPlan = styled.h3`
    color: #1c2237;
    margin-bottom: 5px;
    font-size: 24px;
`;

export const ContactCardCost = styled.h4`
    font-size: 40px;
`;

export const ContactCardLength = styled.p`
    font-size: 14px;
    margin-bottom: 24px;
`;

export const ContactCardFeatures = styled.ul`
    margin: 16px 0 32px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #a9b3c1;
`;

export const ContactCardFeature = styled.li`
    margin-bottom: 10px;
    text-align: center;
`;
