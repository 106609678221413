import {
    FaFacebook,
    FaInstagram,
    FaWhatsapp,
    FaTelegram,
    FaPhone,
    FaMapMarker,
} from "react-icons/fa";

import React from "react";

export const homeObjOne = {
    primary: true,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: "Dott. Mirco Benetti",
    headline: "La Nostra Filosofia",
    description:
        "PASSIONE DENTALE: essenza del significato profondo della nostra visione di odontoiatria. Il nostro punto di forza è la professionalità e la ricerca della perfezione in ogni branca dell’odontoiatria, in un ambiente rilassante e familiare. Abbiamo una particolare attenzione per l’estetica dentale perchè siamo convinti che un bel sorriso sia il biglietto da visita migliore per una vita di relazione.",
    buttonLabel: "Chiama",
    buttonLink: "tel:0456103637",
    buttonInfo: [
        {
            link: "tel:0456103637",
            label: "Chiama",
            icon: <FaPhone className="home-button-icon" />,
        },
        {
            link: "https://g.page/studio-dentistico-dott--benetti?share",
            label: "Google Maps",
            icon: <FaMapMarker className="home-button-icon" />,
        },
        {
            link: "https://api.whatsapp.com/send?text=https%3A%2F%2Fpassionedentale.com%2F",
            label: "WhatsApp",
            icon: <FaWhatsapp className="home-button-icon" />,
        },
    ],
    imgStart: "",
    img: require("../../images/svg-3.svg"),
    alt: "Il nostro studio",
    start: "",
};

export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: "",
    headline: "Dove e Quando",
    description:
        'Lo studio si trova a Monteforte d\'Alpone (VR), Viale Europa 22/D. Riceviamo su appuntamento, chiamare il numero +39 045 6103637 o cliccare sul pulsante "Chiama"',
    buttonLabel: "Learn More",
    imgStart: "",
    img: require("../../images/svg-4.svg"),
    alt: "Vault",
    start: "",
};

export const homeObjFour = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: "Domande frequenti",
    headline:
        "Non trovi la sede? Hai domande per il nostro studio? Chiama o guarda la nostra sezione FAQ",
    description:
        "Passione Dentale Srl non è direttamente visibile dalla strada: si trova all'interno di una zona residenziale accessibile dal parcheggio rialzato di Viale Europa e dal parcheggio delle scuole medie. Impostado raggiungi a piedi su Google maps arriverete di fronte al nostro ingresso!",
    buttonLabel: "Faq",
    imgStart: "start",
    img: require("../../images/svg-4.svg"),
    alt: "Vault",
    start: "end",
    pageLink: "/faq",
};
