import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import Logo from "../../images/logo.png";
import { Button } from "../../globalStyles";

import "./navbar.css";

import {
    Nav,
    NavbarContainer,
    NavLogo,
    NavIcon,
    MobileIcon,
    NavMenu,
    NavItem,
    NavItemBtn,
    NavLinks,
    NavBtnLink,
} from "./Navbar.elements";

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener("resize", showButton);

    return (
        <>
            <IconContext.Provider value={{ color: "#fff" }}>
                <Nav>
                    <NavbarContainer>
                        <NavLogo to="/" onClick={closeMobileMenu}>
                            {/* <NavIcon /> */}
                            <img src={Logo} className="logo-intestazione" />
                            <div className="intestazione">Passione Dentale</div>
                        </NavLogo>
                        <MobileIcon onClick={handleClick}>
                            {click ? <FaTimes /> : <FaBars />}
                        </MobileIcon>
                        <NavMenu onClick={handleClick} click={click}>
                            <NavItem>
                                <NavLinks to="/" onClick={closeMobileMenu}>
                                    Home
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks
                                    to="/contacts"
                                    onClick={closeMobileMenu}
                                >
                                    Contatti
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks
                                    to="/services"
                                    onClick={closeMobileMenu}
                                >
                                    Servizi
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks
                                    to="/glossary"
                                    onClick={closeMobileMenu}
                                >
                                    Glossario
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="/faq" onClick={closeMobileMenu}>
                                    FAQ
                                </NavLinks>
                            </NavItem>
                            {/* <NavItemBtn>
                                {button ? (
                                    <NavBtnLink to="/sign-up">
                                        <Button primary>SIGN UP</Button>
                                    </NavBtnLink>
                                ) : (
                                    <NavBtnLink to="/sign-up">
                                        <Button
                                            onClick={closeMobileMenu}
                                            fontBig
                                            primary
                                        >
                                            SIGN UP
                                        </Button>
                                    </NavBtnLink>
                                )}
                            </NavItemBtn> */}
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    );
}

export default Navbar;
