import React from "react";
import { InfoSection, Contact } from "../../components";
import { homeObjOne, homeObjThree } from "./Data";

function Services() {
    return (
        <>
            <Contact />
        </>
    );
}

export default Services;
