import React from "react";
import { Button } from "../../globalStyles";
import Logo from "../../images/logo.png";
import {
    FaFacebook,
    FaInstagram,
    FaYoutube,
    FaTwitter,
    FaLinkedin,
    FaWhatsapp,
    FaTelegram,
} from "react-icons/fa";
import {
    FooterContainer,
    FooterSubscription,
    FooterSubText,
    FooterSubHeading,
    Form,
    FormInput,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    SocialIcon,
    WebsiteRights,
    SocialIcons,
    SocialIconLink,
} from "./Footer.elements";

function Footer() {
    return (
        <FooterContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to="/">
                        <img
                            src={Logo}
                            style={{
                                minHeight: "1.2em",
                                maxHeight: "2em",
                                margin: "1em",
                            }}
                        />
                        Passione Dentale
                    </SocialLogo>
                    <WebsiteRights>
                        <p style={{ textAlign: "center" }}>
                            Passione Dentale Srl
                        </p>
                        <p style={{ textAlign: "center" }}>P.IVA 04825770235</p>
                        <p style={{ textAlign: "center" }}>
                            Direttore Sanitario Dott. Benetti Mirco albo dei
                            medici e degli odontoiatri di Verona n°01257
                        </p>
                        <p style={{ textAlign: "center" }}>
                            informazione pubblicitaria, ai sensi della legge 248
                            del 2006 e della legge 145 del 2018
                        </p>
                        <p style={{ textAlign: "center" }}>
                            Autorizzazione sanitaria n°25/2021 del 27-12-2021
                        </p>
                    </WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink
                            href="https://www.facebook.com/sharer/sharer.php?u=https://passionedentale.com"
                            target="_blank"
                            aria-label="Facebook"
                        >
                            <FaFacebook />
                        </SocialIconLink>
                        <SocialIconLink
                            href="https://api.whatsapp.com/send?text=%0ahttps://passionedentale.com"
                            target="_blank"
                            aria-label="Facebook"
                        >
                            <FaWhatsapp />
                        </SocialIconLink>
                        <SocialIconLink
                            href="https://xn--r1a.link/share/url?url=https%3A%2F%2Fpassiondentale.com"
                            target="_blank"
                            aria-label="Facebook"
                        >
                            <FaTelegram />
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterContainer>
    );
}

export default Footer;
