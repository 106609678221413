import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../globalStyles";
import { AiFillThunderbolt } from "react-icons/ai";
import { GiCrystalBars } from "react-icons/gi";
import { GiCutDiamond, GiRock } from "react-icons/gi";
import { GiFloatingCrystal } from "react-icons/gi";
import { IconContext } from "react-icons/lib";
import {
    ServiceSection,
    ServiceWrapper,
    ServiceHeading,
    ServiceContainer,
    ServiceCard,
    ServiceCardInfo,
    ServiceCardIcon,
    ServiceCardPlan,
    ServiceCardCost,
    ServiceCardLength,
    ServiceCardFeatures,
    ServiceCardFeature,
} from "./Service.elements";

import estetica from "../../images/services/icona_estetica_blue.svg";
import protesi from "../../images/services/icona_protesi_blue.svg";
import chirurgia from "../../images/services/icona_chirurgia_blue.svg";
import parodontologia from "../../images/services/icona_parodontologia_blue.svg";
import gnatologia from "../../images/services/icona_gnatologia_blue.svg";
import ortodonzia from "../../images/services/icona_ortodonzia_blue.svg";
import conservativa from "../../images/services/icona_conservativa_blue.svg";
import prevenzione from "../../images/services/icona_prevenzione_blue.svg";
import terapia_sonno from "../../images/services/icona_terapia_sonno_blue.svg";
function Service() {
    return (
        <IconContext.Provider value={{ color: "#a9b3c1", size: 64 }}>
            <ServiceSection>
                <ServiceWrapper>
                    <ServiceHeading>I nostri servizi</ServiceHeading>
                    <ServiceContainer>
                        <ServiceCard>
                            <ServiceCardInfo>
                                <img
                                    src={estetica}
                                    style={{ width: "2.5em" }}
                                />
                                <ServiceCardPlan>ESTETICA</ServiceCardPlan>
                                <ServiceCardFeatures>
                                    <ServiceCardFeature>
                                        Sbiancamenti, faccette, corone altamente
                                        estetiche, ricostruzione denti
                                        fratturati, correzione degli inestetismi
                                        dentali e del sorriso, realizzazione di
                                        dentature provvisorie su misura con
                                        estetiche particolari per attori,
                                        teatro, maschere, cosplay.
                                    </ServiceCardFeature>
                                </ServiceCardFeatures>
                            </ServiceCardInfo>
                        </ServiceCard>
                        <ServiceCard>
                            <ServiceCardInfo>
                                <img src={protesi} style={{ width: "2.5em" }} />
                                <ServiceCardPlan>PROTESI</ServiceCardPlan>
                                <ServiceCardFeatures>
                                    <ServiceCardFeature>
                                        NB: tutte le protesi sono realizzate con
                                        tecniche e materiali altamente estetici
                                        e certificati. Realizziamo tutti i tipi
                                        di protesi: fissa, mobile su denti
                                        naturali e su impianti. Sono a partire
                                        dal dente singolo fino alla bocca
                                        intera.
                                    </ServiceCardFeature>
                                </ServiceCardFeatures>
                            </ServiceCardInfo>
                        </ServiceCard>
                        <ServiceCard>
                            <ServiceCardInfo>
                                <img
                                    src={chirurgia}
                                    style={{ width: "2.5em" }}
                                />
                                <ServiceCardPlan>CHIRURGIA</ServiceCardPlan>
                                <ServiceCardFeatures>
                                    <ServiceCardFeature>
                                        Impianti osteointegrati, correzione
                                        della forma delle parabole gengivali
                                        (recessioni, gengivectomie, alterata
                                        eruzione passiva), estrazioni dentarie,
                                        innesti di osso, rialzi di seno
                                        mascellare, innesti di tessuto gengivale
                                        (connettivo).
                                    </ServiceCardFeature>
                                </ServiceCardFeatures>
                            </ServiceCardInfo>
                        </ServiceCard>
                    </ServiceContainer>
                    <ServiceContainer>
                        <ServiceCard>
                            <ServiceCardInfo>
                                <img
                                    src={parodontologia}
                                    style={{ width: "2.5em" }}
                                />
                                <ServiceCardPlan>
                                    PARODONTOLOGIA
                                </ServiceCardPlan>
                                <ServiceCardFeatures>
                                    <ServiceCardFeature>
                                        Terapia della parodontite, igiene orale
                                        profonda delle tasche, terapia della
                                        parodontite scaling, interventi
                                        parodontali, recessioni gengivali.
                                    </ServiceCardFeature>
                                </ServiceCardFeatures>
                            </ServiceCardInfo>
                        </ServiceCard>
                        <ServiceCard>
                            <ServiceCardInfo>
                                <img
                                    src={gnatologia}
                                    style={{ width: "2.5em" }}
                                />
                                <ServiceCardPlan>GNATOLOGIA</ServiceCardPlan>
                                <ServiceCardFeatures>
                                    <ServiceCardFeature>
                                        Visite gnatologiche, analisi
                                        masticazione, bite per bruxismo,
                                        riabilitazioni estetico funzionali per
                                        pazienti con bruxismo.
                                    </ServiceCardFeature>
                                </ServiceCardFeatures>
                            </ServiceCardInfo>
                        </ServiceCard>
                        <ServiceCard>
                            <ServiceCardInfo>
                                <img
                                    src={ortodonzia}
                                    style={{ width: "2.5em" }}
                                />
                                <ServiceCardPlan>ORTODONZIA</ServiceCardPlan>
                                <ServiceCardFeatures>
                                    <ServiceCardFeature>
                                        Ortodonzia con apparecchi “invisibili”,
                                        ortodonzia mobile, ortodonzia fissa,
                                        ortodonzia intercettiva, ortodonzia
                                        funzionale, attivatori ortodontici.
                                    </ServiceCardFeature>
                                </ServiceCardFeatures>
                            </ServiceCardInfo>
                        </ServiceCard>
                    </ServiceContainer>
                    <ServiceContainer>
                        <ServiceCard>
                            <ServiceCardInfo>
                                <img
                                    src={conservativa}
                                    style={{ width: "2.5em" }}
                                />
                                <ServiceCardPlan>CONSERVATIVA</ServiceCardPlan>
                                <ServiceCardFeatures>
                                    <ServiceCardFeature>
                                        Otturazioni di piccole,medie e grandi
                                        dimensioni, ricostruzioni dentali
                                        estese, ricostruzioni con perni
                                        endocanalari, intarsi, overlay, onlay,
                                        trattamenti desensibilizzanti,
                                        endodonzia, devitalizzazioni.
                                    </ServiceCardFeature>
                                </ServiceCardFeatures>
                            </ServiceCardInfo>
                        </ServiceCard>
                        <ServiceCard>
                            <ServiceCardInfo>
                                <img
                                    src={prevenzione}
                                    style={{ width: "2.5em" }}
                                />
                                <ServiceCardPlan>PREVENZIONE</ServiceCardPlan>
                                <ServiceCardFeatures>
                                    <ServiceCardFeature>
                                        Insegnamenti alla corretta igiene orale
                                        domiciliare specifica per ogni paziente,
                                        controlli sulla corretta pratica di
                                        igiene orale, igiene orale
                                        superificiale, ablazione tartaro, visite
                                        di controllo con occhialini
                                        ingranditori, radiografie e metodi di
                                        transilluminazione dentale, paradenti
                                        per attività sportive traumatiche (arti
                                        marziali, motocross, etc).
                                    </ServiceCardFeature>
                                </ServiceCardFeatures>
                            </ServiceCardInfo>
                        </ServiceCard>
                        <ServiceCard>
                            <ServiceCardInfo>
                                <img
                                    src={terapia_sonno}
                                    style={{ width: "2.5em" }}
                                />
                                <ServiceCardPlan>
                                    TERAPIA DEL SONNO
                                </ServiceCardPlan>
                                <ServiceCardFeatures>
                                    <ServiceCardFeature>
                                        Dispositivi anti russamento, avanzatori
                                        mandibolari per la terapia delle apnee
                                        ostruttive del sonno OSAS.
                                    </ServiceCardFeature>
                                </ServiceCardFeatures>
                            </ServiceCardInfo>
                        </ServiceCard>
                    </ServiceContainer>
                </ServiceWrapper>
            </ServiceSection>
        </IconContext.Provider>
    );
}
export default Service;
