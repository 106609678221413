import React from "react";
import GlobalStyle from "./globalStyles";
import Home from "./pages/HomePage/Home";
import Services from "./pages/Services/Services";

// da implementare
import contacts from "./pages/contacts/Contacts";
import faq from "./pages/faq/Faq";
import glossary from "./pages/glossary/Glossary";
// da implementare

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { Navbar, Footer } from "./components";

function App() {
    return (
        <Router>
            <GlobalStyle />
            <ScrollToTop />
            <Navbar />
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/contacts" exact component={contacts} />
                <Route path="/services" exact component={Services} />
                <Route path="/faq" exact component={faq} />
                <Route path="/glossary" exact component={glossary} />
                <Route component={Home} />
            </Switch>
            <Footer />
        </Router>
    );
}

export default App;
