import React from "react";
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from "./Data";
import { InfoSection, InfoSectionHome, Pricing } from "../../components";

function Home() {
    return (
        <>
            <InfoSectionHome {...homeObjOne} />
            <InfoSection {...homeObjFour} />
            {/* <InfoSection {...homeObjTwo} /> */}
            <Pricing />
        </>
    );
}

export default Home;
