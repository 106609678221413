import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../globalStyles";
import { AiFillThunderbolt } from "react-icons/ai";
import { GiCrystalBars } from "react-icons/gi";
import { GiCutDiamond, GiRock } from "react-icons/gi";
import { GiFloatingCrystal } from "react-icons/gi";
import { IconContext } from "react-icons/lib";
import {
    PricingSection,
    PricingWrapper,
    PricingHeading,
    PricingContainer,
    PricingCard,
    PricingCardInfo,
    PricingCardIcon,
    PricingCardPlan,
    PricingCardCost,
    PricingCardLength,
    PricingCardFeatures,
    PricingCardFeature,
} from "./Pricing.elements";

function Pricing() {
    return (
        <IconContext.Provider value={{ color: "#a9b3c1", size: 64 }}>
            <PricingSection>
                <PricingWrapper>
                    <PricingHeading>I nostri servizi</PricingHeading>
                    <PricingContainer>
                        <PricingCard>
                            <PricingCardInfo>
                                {/* <PricingCardIcon>
                                    <GiRock />
                                </PricingCardIcon> */}
                                <PricingCardPlan>
                                    Impanti Dentali
                                </PricingCardPlan>
                                <PricingCardFeatures>
                                    <PricingCardFeature>
                                        Un impianto dentale è una piccola vite
                                        in titanio progettata per sostituire la
                                        radice di un dente naturale mancante. Il
                                        titanio è un materiale perfettamente
                                        integrabile; è lo stesso materiale
                                        utilizzato per la costruzione di protesi
                                        ortopediche. Gli impianti dentali sono
                                        inseriti nell’osso laddove prima c’erano
                                        i denti naturali.
                                    </PricingCardFeature>
                                </PricingCardFeatures>
                            </PricingCardInfo>
                        </PricingCard>
                        <PricingCard>
                            <PricingCardInfo>
                                {/* <PricingCardIcon>
                                    <GiRock />
                                </PricingCardIcon> */}
                                <PricingCardPlan>
                                    Estetica Dentale
                                </PricingCardPlan>
                                <PricingCardFeatures>
                                    <PricingCardFeature>
                                        Ci occupiamo dell’estetica dentale a 360
                                        gradi, dalle piccole discromie a
                                        ricostruzioni estetiche, sbiancamenti,
                                        faccette, corone e grandi riabilitazioni
                                        in cui l’estetica gioca un ruolo
                                        fondamentale.
                                    </PricingCardFeature>
                                </PricingCardFeatures>
                            </PricingCardInfo>
                        </PricingCard>
                        <PricingCard>
                            <PricingCardInfo>
                                {/* <PricingCardIcon>
                                    <GiRock />
                                </PricingCardIcon> */}
                                <PricingCardPlan>
                                    Protesi Dentale
                                </PricingCardPlan>
                                <PricingCardFeatures>
                                    <PricingCardFeature>
                                        Ci occupiamo di piccole e grandi
                                        riabilitazioni, anche di tutta l’arcata
                                        dentaria. Sono disponibili protesi fisse
                                        oppure mobili (Toronto, All On Four,
                                        Protesi totale, dentiere).
                                    </PricingCardFeature>
                                </PricingCardFeatures>
                            </PricingCardInfo>
                        </PricingCard>
                    </PricingContainer>
                    <Link to="/services" style={{ marginTop: "2em" }}>
                        <Button big fontBig primary={"primary"}>
                            {"Scopri di più!"}
                        </Button>
                    </Link>
                </PricingWrapper>
            </PricingSection>
        </IconContext.Provider>
    );
}
export default Pricing;
