import React from "react";
import { Link } from "react-router-dom";
import { Container, Button } from "../../globalStyles";
import "./InfoSectionHome.css";
import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    ImgWrapper,
    Img,
} from "./InfoSection.elements";

function InfoSection({
    primary,
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonLabel,
    buttonLink,
    img,
    alt,
    imgStart,
    start,
    pageLink,
}) {
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                                <TopLine lightTopLine={lightTopLine}>
                                    {topLine}
                                </TopLine>
                                <Heading lightText={lightText}>
                                    {headline}
                                </Heading>
                                <Subtitle lightTextDesc={lightTextDesc}>
                                    {description}
                                </Subtitle>
                                {buttonLink ? (
                                    <a href={buttonLink}>
                                        <Button
                                            big
                                            fontBig
                                            primary={primary}
                                            style={{
                                                margin: "10px",
                                                width: "300px",
                                            }}
                                            className="home-buttons"
                                        >
                                            {buttonLabel}
                                        </Button>
                                    </a>
                                ) : (
                                    <Link to={pageLink}>
                                        <Button big fontBig primary={primary}>
                                            {buttonLabel}
                                        </Button>
                                    </Link>
                                )}
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <ImgWrapper start={start}>
                                <Img src={img} alt={alt} />
                            </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    );
}

export default InfoSection;
