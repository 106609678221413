import { Link } from "react-router-dom";
import styled from "styled-components";

export const FaqSection = styled.div`
    padding: 100px 0 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
`;

export const FaqWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    @media screen and (max-width: 960px) {
        margin: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const FaqHeading = styled.h1`
    color: #1c2237;
    font-size: 48px;
    margin-bottom: 24px;
`;

export const FaqContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 960px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`;

export const FaqCard = styled.div`
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
    width: 70%;
    min-height: 330px;
    text-decoration: none;
    border-radius: 4px;
    margin: 2em;
    width: 80%;

    &:nth-child(2) {
        margin: 24px;
    }

    &:hover {
        // transform: scale(1.06);
        transition: all 0.3s ease-out;
        color: #1c2237;
    }

    @media screen and (max-width: 960px) {
        width: 95%;
        min-height: 0px;

        &:hover {
            transform: none;
        }
    }
`;

export const FaqCardInfo = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 330px;
    padding: 24px;
    align-items: center;
    color: #fff;
    @media screen and (max-width: 960px) {
        min-height: 0px;
    }
`;

export const FaqCard1 = styled.div`
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
    width: 70%;
    text-decoration: none;
    border-radius: 4px;
    margin: 2em;
    width: 80%;

    &:nth-child(2) {
        margin: 24px;
    }

    &:hover {
        // transform: scale(1.06);
        transition: all 0.3s ease-out;
        color: #1c2237;
    }

    @media screen and (max-width: 960px) {
        width: 95%;
        min-height: 0px;

        &:hover {
            transform: none;
        }
    }
`;

export const FaqCardInfo1 = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    align-items: center;
    color: #fff;
    @media screen and (max-width: 960px) {
        min-height: 0px;
    }
`;

export const FaqCardIcon = styled.div`
    margin: 24px 0;
`;

export const FaqCardPlan1 = styled.h3`
    color: #1c2237;
    margin-bottom: 10px;
    font-size: 24px;
`;

export const FaqCardPlan2 = styled.h3`
    // color: #1c2237;
    color: #5ea9c4;
    margin-bottom: 5px;
    font-size: 24px;
`;

export const FaqCardCost = styled.h4`
    font-size: 40px;
`;

export const FaqCardLength = styled.p`
    font-size: 14px;
    margin-bottom: 24px;
`;

export const FaqCardFeatures = styled.ul`
    margin: 16px 0 32px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #a9b3c1;
`;

export const FaqCardFeatures1 = styled.ul`
    margin: 16px 0 32px;
    list-style: none;
    display: flex;
    flex-direction: column;
    color: #a9b3c1;
    justify-content: start;
    width: 100%;
`;

export const FaqCardFeature = styled.li`
    margin-bottom: 10px;
    text-align: center;
`;

export const FaqCardFeature1 = styled.li`
    margin-bottom: 10px;
    // text-align: left;
    display: flex;
    justify-content: start;
`;

export const FaqInput = styled.input`
    font-size: 16px;
    line-height: 28px;
    padding: 8px 16px;
    width: 100%;
    min-height: 44px;
    border: unset;
    border-radius: 4px;
    // outline-color: #5ea9c4;
    outline: none;
    border: 1px solid #1c2237;
    &:focus {
        border: 1px solid #5ea9c4;
    }
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px;
`;
